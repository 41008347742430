/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const SET_REPORT = 'SET_REPORT'
export const RESET_REPORT = 'RESET_REPORT'

export const setReport = (report: any) => {
  return {
    type: SET_REPORT,
    payload: {
      ...report,
    },
  }
}

export const resetReport = () => {
  return {
    type: RESET_REPORT,
    payload: {
      ...[],
    },
  }
}
