import {
  dummyBusinessTypeMaster,
  dummyCorporation,
  dummyStresscheck,
  dummyUser,
  dummyUserCorporationRelation,
  dummyYearlySummary,
} from '../../constants/dummy'
import {
  Corporation,
  Employee,
  Stresscheck,
  StresscheckDetail,
  User,
  UserCorporationRelation,
  YearlySummary,
} from '../../models'
import { BusinessTypeMaster } from '../../models/businessTypeMaster'

export type state = {
  auth: {
    authError: string
  }
  firebase: any
  coreui: {
    sidebarShow: boolean
    sidebarUnfoldable: boolean
    asideShow: boolean
    unfoldable: boolean
    theme: string
  }
  // Custom States
  user: User
  users: User[]
  businessTypeMaster: BusinessTypeMaster
  corporation: Corporation
  yearlySummary: YearlySummary
  employees: Employee[]
  stresscheck: any //TODO Stresscheckではエラーが出る。恐らくgetStatusのせい。
  stresschecks: Stresscheck[]
  stresscheckDetails: StresscheckDetail[]
  userCorporationRelation: UserCorporationRelation
  userCorporationRelations: UserCorporationRelation[]
  analysis: any
  response: any
  report: any
}

const initialState: state = {
  auth: {
    authError: '',
  },
  firebase: {},
  coreui: {
    sidebarShow: true,
    sidebarUnfoldable: false,
    asideShow: false,
    unfoldable: false,
    theme: 'default',
  },
  user: dummyUser,
  users: [],
  businessTypeMaster: dummyBusinessTypeMaster,
  corporation: dummyCorporation,
  yearlySummary: dummyYearlySummary,
  employees: [],
  stresscheck: dummyStresscheck,
  stresschecks: [],
  stresscheckDetails: [],
  userCorporationRelation: dummyUserCorporationRelation,
  userCorporationRelations: [],
  analysis: {},
  response: {},
  report: {},
}

export default initialState
